const config = require('./config.default');

config.marketingUrl = 'https://liberty-rider.com';
config.sharingUrl = 'https://rider.live';
config.apiUrl = 'https://api.liberty-rider.com/graphql';
config.trackApiUrl = 'https://track-api.liberty-rider.com';
config.emergencyApiUrl = 'https://ecall.liberty-rider.com';

config.firebase.clientConfig = {
  apiKey: 'AIzaSyCMLiqF3hrzqnf4ltuQjLMPSPh19rgDL5c',
  authDomain: 'firebase.liberty-rider.com',
  databaseURL: 'https://project-5067638840241668536.firebaseio.com',
  projectId: 'project-5067638840241668536',
  storageBucket: 'project-5067638840241668536.appspot.com',
  messagingSenderId: '907933492180',
};
config.firebase.emergencyBucket = 'prod-sdk-emergency';
config.firebase.debugBucket = 'prod-sdk-debug';

config.analytics.enable = true;

config.sentry.enabled = true;
config.sentry.dsn = 'https://d3f4caa0cf7e4acfa8cc8ca39418d47d@sentry.io/1468845';

// TODO: config.jawgMaps.key = '';

config.openmaptiles.url = 'https://tiles.liberty-rider.com/styles/osm-liberty/style.json';

config.logging.metricsNamespace = process.env.METRICS_NAMESPACE || 'ride-react.production';
config.logging.monitorHttpStatus = true;
config.logging.addTraceId = true;

config.forestUrlRide = 'https://app.forestadmin.com/Liberty%20Admin/Production/Operators/data/2117628/index/record/2117628/';
config.forestUrlUser = 'https://app.forestadmin.com/Liberty%20Admin/Production/Operators/data/2117637/index/record/2117637/';
config.metabaseEvents = 'https://metabase.liberty-rider.com/question/1436';

module.exports = config;
